// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-abc-inspirate-js": () => import("./../../../src/pages/abc-inspirate.js" /* webpackChunkName: "component---src-pages-abc-inspirate-js" */),
  "component---src-pages-anadir-inspiranos-js": () => import("./../../../src/pages/anadir-inspiranos.js" /* webpackChunkName: "component---src-pages-anadir-inspiranos-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sprawdz-js": () => import("./../../../src/pages/sprawdz.js" /* webpackChunkName: "component---src-pages-sprawdz-js" */)
}

